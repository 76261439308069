import { createRouter, createWebHistory } from 'vue-router';
import store from '../store'; // Assuming you have a Vuex store

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard/UserDashboard.vue'),
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/map/:projectFolder',
    name: 'map',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../components/Map/Map.vue'),
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue') // No authentication required
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: () => import('../components/Generics/404NotFound.vue'),
    meta: { requiresAuth: true } // Require authentication
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = store.state.auth.accessToken; // Get the accessToken directly from Vuex store's state

  if (requiresAuth && !token) {
    next({ name: 'login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;