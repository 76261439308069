import AWS from "aws-sdk";
import { Amplify } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { configureAWSCredentials } from "../../aws-config";

Amplify.configure(awsconfig);

export const state = {
  isAuthenticated: !!localStorage.getItem("accessToken"),
  user: JSON.parse(localStorage.getItem("user")) || {},
  accessToken: localStorage.getItem("accessToken"),
};

export const mutations = {
  user(state, user) {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  authenticateMutation(state, { user, accessToken, username, password }) {
    console.log("authenticateMutation called", {
      user,
      accessToken,
      username,
      password,
    });
    state.isAuthenticated = true;
    state.user = { ...user, username, password }; // Store username and password in user object
    state.accessToken = accessToken;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  logoutMutation(state, logoutResult) {
    console.log("logoutMutation called", { logoutResult });
    state.isAuthenticated = logoutResult;
    state.user = {};
    state.accessToken = null;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
  },
};

export const actions = {
  async authenticate({ commit }, authDetails) {
    try {
      const username = authDetails.username;
      const password = authDetails.password;

      // Use AWS SDK for authentication
      const cognito = new AWS.CognitoIdentityServiceProvider({
        region: awsconfig.aws_project_region,
      });
      const params = {
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: awsconfig.aws_user_pools_web_client_id,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
        },
      };
      const authResult = await cognito.initiateAuth(params).promise();
      const accessToken = authResult.AuthenticationResult.AccessToken;

      // Store tokens in state or local storage as needed
      commit("authenticateMutation", {
        user: authResult.AuthenticationResult,
        accessToken,
        username,
        password,
      });

      // Configure AWS credentials
      await configureAWSCredentials(username, password);
    } catch (e) {
      console.log("error in authenticate ", e);
    }
  },
  async checkAndRefreshToken({ commit, dispatch, state }) {
    try {
      // Use AWS SDK to get current authenticated user
      const cognito = new AWS.CognitoIdentityServiceProvider({
        region: awsconfig.aws_project_region,
      });
      const user = await cognito.getUser().promise();

      commit("authenticateMutation", { user, accessToken: state.accessToken });
    } catch (err) {
      dispatch("logout");
    }
  },
  async setUser({ commit, state }, user) {
    try {
      commit("authenticateMutation", { user, accessToken: state.accessToken });
    } catch (err) {
      commit("authenticateMutation", { user: null, accessToken: null });
    }
  },
  async logout({ commit, dispatch, state }) {
    try {
      console.log("logout action called", new Date().toISOString());
      console.log("logging out", state.user);

      // Use AWS SDK to sign out
      const cognito = new AWS.CognitoIdentityServiceProvider({
        region: awsconfig.aws_project_region,
      });
      const params = {
        AccessToken: state.accessToken, // Ensure accessToken is available in state
      };
      await cognito.globalSignOut(params).promise();

      dispatch("clearMap");
      commit("logoutMutation", false);
    } catch (e) {
      console.log("error in logout ", e);
    }
  },
};

export const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  user: (state) => state.user,
  accessToken: (state) => state.accessToken,
};
