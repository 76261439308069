import zoomGridIntervals from '@/data/zoomGridIntervals'
import AWS from 'aws-sdk'
import { configureAWSCredentials } from '@/aws-config'

export const state = {
  map: null,
  accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
  zoomGridIntervals,
  currentZoom: 4,
  currentInterval: 10,
  currentGridLineWidth: 0.1,
  mapLatitude: -68.947,
  mapLongitude: 46.55,
  s3: null,
  s3Config: null,
  metadata: null,
  dataStructure: [],
  availableZoomLevels: [],
  progressVisible: false,
  progressPercentage: 0,
  chosenOutputFolder: 'outputs/'
}

export const mutations = {
  setInitialisedMap (state, map) {
    state.map = map
  },
  setClearMap (state) {
    state.map = null
  },
  setS3 (state, s3) {
    state.s3 = s3
  },
  setS3Config (state, s3Config) {
    state.s3Config = s3Config
  },
  setMetadata (state, metadata) {
    state.metadata = metadata
  },
  setDataStructure (state, dataStructure) {
    state.dataStructure = dataStructure
  },
  setAvailableZoomLevels (state, zoomLevels) {
    state.availableZoomLevels = zoomLevels
  },
  setCurrentZoom (state, zoom) {
    state.currentZoom = zoom
  },
  setProgressVisible (state, visible) {
    state.progressVisible = visible
  },
  setProgressPercentage (state, percentage) {
    state.progressPercentage = percentage
  },
  setChosenOutputsFolder (state, folder) {
    state.chosenOutputFolder = 'outputs/' + folder
  }
}

export const actions = {
  chooseOutputFolder ({ commit }, folder) {
    commit('setChosenOutputsFolder', folder)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}