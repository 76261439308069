import { openDB } from 'idb';
import LZString from 'lz-string';

const DB_NAME = 'vectorTileDB';
const TILE_STORE = 'tileZoomDictionary';
const GEOJSON_STORE = 'geojsonCache';

export const state = {
  tileZoomDictionary: {},
  geojsonCache: {}
};

export const mutations = {
  setTileZoomDictionary(state, { zoom, data }) {
    state.tileZoomDictionary[zoom] = data;
  },
  setGeoJSONCache(state, { zoom, geojson }) {
    state.geojsonCache[zoom] = geojson;
  }
};

const initDB = async () => {
  const db = await openDB(DB_NAME, 1, {
    upgrade(db) {
      db.createObjectStore(TILE_STORE);
      db.createObjectStore(GEOJSON_STORE);
    },
  });
  return db;
};

export const actions = {
  async loadTileZoomDictionary({ commit }) {
    const db = await initDB();
    const keys = await db.getAllKeys(TILE_STORE);
    for (const zoom of keys) {
      const cachedTileZoomDictionary = await db.get(TILE_STORE, zoom);
      if (cachedTileZoomDictionary) {
        const decompressedData = JSON.parse(LZString.decompress(cachedTileZoomDictionary));
        commit('setTileZoomDictionary', { zoom, data: decompressedData });
      }
    }
  },
  async saveTileZoomDictionary({ state }) {
    const db = await initDB();
    console.log('db initialised. ')
    for (const zoom in state.tileZoomDictionary) {
      console.log('being saved', zoom);
      const compressedData = LZString.compress(JSON.stringify(state.tileZoomDictionary[zoom]));
      console.log('compressed complete')
      await db.put(TILE_STORE, compressedData, zoom);
      console.log('saved to db')
    }
  },
  async loadGeoJSONCache({ commit }) {
    const db = await initDB();
    const keys = await db.getAllKeys(GEOJSON_STORE);
    for (const zoom of keys) {
      const cachedGeoJSONCache = await db.get(GEOJSON_STORE, zoom);
      if (cachedGeoJSONCache) {
        const decompressedData = JSON.parse(LZString.decompress(cachedGeoJSONCache));
        commit('setGeoJSONCache', { zoom, geojson: decompressedData });
      }
    }
  },
  async saveGeoJSONCache({ state }) {
    const db = await initDB();
    for (const zoom in state.geojsonCache) {
      const compressedData = LZString.compress(JSON.stringify(state.geojsonCache[zoom]));
      await db.put(GEOJSON_STORE, compressedData, zoom);
    }
  },
};
